import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    invoices: [],
    invoice: null,
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0
    },
    pdfStatus: 'idle',
    pdf: null,
};
// ----------------------------------------------------------------------
export const getInvoices = createAsyncThunk('invoices/getInvoices', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', filterCustomerId = '', filterStatus = '', isPaging = true, linked = '', }) => {
    let data;
    const queryParams = {
        search: {
            linked,
            invoiceNumber: filterName,
            'customer.id': filterCustomerId,
            status: filterStatus,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/invoices', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200)
            return data;
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getInvoice = createAsyncThunk('invoices/getInvoice', async (id) => {
    let data;
    try {
        const response = await axios.get(`/invoices/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createInvoice = createAsyncThunk('invoices/createInvoice', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/invoices`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editInvoice = createAsyncThunk('invoices/editInvoice', async ({ id, Data }) => {
    let data;
    try {
        const response = await axios.put(`/invoices/${id}`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const unlinkInvoice = createAsyncThunk('invoices/unlinkInvoice', async (id) => {
    let data;
    try {
        const response = await axios.patch(`/invoices/${id}/unlink`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const changeStatus = createAsyncThunk('invoices/changeStatus', async ({ id }) => {
    let data;
    try {
        const response = await axios.patch(`/invoices/${id}/changeStatus`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteInvoice = createAsyncThunk('invoices/deleteInvoice', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/invoices/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getInvoices({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyInvoices = createAsyncThunk('invoices/deleteManyInvoices', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/invoices/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getInvoices({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getInvoicePdf = createAsyncThunk('invoices/getInvoicePdf', async (id) => {
    let data;
    try {
        const response = await axios.get(`/invoices/${id}/pdf`, {
            responseType: 'arraybuffer',
        });
        data = await response.data;
        if (response.status === 200) {
            return new Uint8Array(data);
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setInvoiceStatus(state, action) {
            if (state.invoice)
                state.invoice.status = action.payload.status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInvoices.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getInvoices.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.invoices = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getInvoices.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getInvoice.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getInvoice.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.invoice = action.payload.data;
        })
            .addCase(getInvoice.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getInvoicePdf.pending, (state) => {
            state.pdfStatus = 'loading';
        })
            .addCase(getInvoicePdf.fulfilled, (state, action) => {
            state.pdfStatus = 'succeeded';
            state.pdf = action.payload;
        })
            .addCase(getInvoicePdf.rejected, (state, action) => {
            state.pdfStatus = 'failed';
        });
    },
});
export const { setInvoiceStatus } = slice.actions;
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
